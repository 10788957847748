import { render, staticRenderFns } from "./Atorvastatin-game.vue?vue&type=template&id=691bee87&scoped=true"
import script from "./Atorvastatin-game.vue?vue&type=script&lang=js"
export * from "./Atorvastatin-game.vue?vue&type=script&lang=js"
import style0 from "./Atorvastatin-game.vue?vue&type=style&index=0&id=691bee87&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "691bee87",
  null
  
)

export default component.exports